<template>
  <div>
    <v-form ref="form" lazy-validation @submit.prevent="salvar">
      <v-row>
        <v-col cols="12" sm="6" md="6" lg="6">
          <v-select
            :items="tiposPagamentos"
            item-text="nome"
            v-model="form.tipoPagamento"
            label="Tipo Pagamento"
            outlined
            dense
            return-object
            ref="tipoPagamento"
            :rules="[rules.required]"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="6" lg="6">
          <vuetify-money
            v-model="form.valor"
            label="Valor"
            v-bind:outlined="true"
            v-bind:options="money"
            v-bind:valueWhenIsEmpty="'0,00'"
            dense
            :rules="[rules.required]"
          />
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6">
          <v-text-field
            label="Data"
            ref="dataPagamento"
            outlined
            dense
            v-model.lazy="form.dataPagamento"
            :rules="[rules.required]"
            v-mask="'##/##/####'"
          ></v-text-field>
        </v-col>

        <v-col cols="2" sm="1" md="1" lg="1" align="right">
          <v-btn color="grey darken-2" dark class="square" @click="reset">
            <v-icon dark> {{ mdiReload }} </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="8" sm="5" md="5" lg="5" align="right">
          <v-btn
            color="primary"
            dark
            :loading="loading"
            :disabled="loading"
            type="submit"
          >
            Salvar
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-card width="100%" class="mx-auto my-12">
        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <!-- <th class="text-left"># Parcela</th> -->
                  <th class="text-left">Tipo Pagamento</th>
                  <th class="text-left">Vencimento</th>
                  <th class="text-left">Valor Parcela</th>
                  <th class="text-left">Pago</th>
                  <th class="text-left">Ação</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in parcelas" :key="index">
                  <!-- <td>{{ item.numero }}</td> -->
                  <td>{{ item.tipoPagamento }}</td>
                  <td>{{ item.dataPagamento | localDateToString }}</td>
                  <td>{{ item.valor | formataMoeda }}</td>
                  <td>
                    <v-switch
                      v-model="item.pago"
                      color="success darken-2"
                      @change="pagar(item)"
                      small
                    >
                    </v-switch>
                  </td>
                  <td>
                    <v-btn icon color="primary" @click="editar(item)"
                      ><v-icon dark> {{ mdiPencilBox }} </v-icon>
                    </v-btn>
                    <v-btn icon color="error" @click="excluir(item)"
                      ><v-icon dark> {{ mdiCloseBox }} </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import formaPagamentoMensal from "@/service/parcelaPagamentoProjeto.js";
import { mdiPencilBox, mdiCloseBox, mdiReload, mdiPlus } from "@mdi/js";
import moment from "moment";
export default {
  name: "ParcelasPagamentoProjeto",
  props: {
    payload: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      mdiPencilBox,
      mdiCloseBox,
      mdiReload,
      mdiPlus,
      form: {
        valor: "",
        dataPagamento: "",
        tipoPagamento: "",
      },
      tiposPagamentos: [],
      rules: {
        required: (value) => !!value || "Obrigatório",
        dias: (value) => (value >= 1 && value <= 31) || "Dia inválido",
      },
      editando: false,
      loading: false,
      parcelas: [],
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false,
      },
    };
  },
  methods: {
    async salvar() {
      if (!this.$refs.form.validate()) return;

      if (!moment(this.form.dataPagamento, "DD/MM/YYYY").isValid()) return;

      const payload = {
        ...this.payload,
        ...this.form,
        dataPagamento: moment(this.form.dataPagamento, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        pago: false,
      };
      delete payload.item;

      try {
        await formaPagamentoMensal.save(payload);
        this.$emit("salvo");
        this.$notify({
          title: "Sucesso",
          text: "Dados salvos!",
          type: "success",
        });
        this.reset();
      } catch (error) {
        this.$notify({
          title: "Erro",
          text: error.message,
          type: "error",
        });
      }
      //this.$emit("sucesso");
      await this.inicializar();
    },
    async buscaParcelas() {
      const resp = await formaPagamentoMensal.fetchParcelasPagamento({
        projetoId: this.payload.projetoId,
      });
      this.parcelas = resp;
    },
    async inicializar() {
      try {
        const resp = await formaPagamentoMensal.fetchTiposPagamento();
        this.tiposPagamentos = resp;
      } catch (error) {
        console.log(error);
      }
      await this.buscaParcelas();
    },
    async pagar(item) {
      if (!item.id) return;

      const payload = {
        id: item.id,
        pago: item.pago,
      };
      try {
        await formaPagamentoMensal.pagarParcela({ ...payload });

        if (item.pago) {
          this.$notify({
            title: "Sucesso",
            text: "Parcela paga!",
            type: "success",
          });
        }
      } catch (error) {
        this.$notify({
          title: "Erro",
          text: "Desculpe, ocorreu um erro no sistema!",
          type: "error",
        });
        item.pago = false;
      }
    },
    editar(item) {
      this.form = {
        ...item,
        dataPagamento: moment(item.dataPagamento, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        ),
      };
    },
    async excluir(item) {
      try {
        await formaPagamentoMensal.delete(item);
        this.$notify({
          title: "Sucesso",
          text: "Parcela excluida!",
          type: "success",
        });
        await this.inicializar();
      } catch (error) {
        console.log(error);
      }
    },
    reset() {
      this.form = {
        valor: "",
        dataPagamento: "",
        tipoPagamento: "",
      };
      this.$refs.form.resetValidation();
    },
  },
  created() {
    this.inicializar();
  },
};
</script>

<style></style>
