import http from "./http"

const atividadeBasica = {


  findAll: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "GET",
        url: `atividadebasica`,
        params: payload
      })
        .then((resp) => { resolve(resp.data) })
        .catch((error) => { reject(error.response.data) })

    })
  },

  findAllByProjetoId: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "GET",
        url: `atividadebasica/projeto`,
        params: payload
      })
        .then((resp) => { resolve(resp.data) })
        .catch((error) => { reject(error.response.data) })

    })
  },

  findById: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "GET",
        url: `atividadebasica?id=${payload.id}`,
      })
        .then((resp) => { resolve(resp.data) })
        .catch((error) => { reject(error.response.data) })
    })
  },

  save: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "POST",
        url: `atividadebasica`,
        data: payload
      })
        .then((resp) => { resolve(resp.data) })
        .catch((error) => { reject(error.response.data) })

    })
  },
  delete: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "DELETE",
        url: `atividadebasica`,
        data: { ...payload }
      })
        .then((resp) => { resolve(resp.data) })
        .catch((error) => { reject(error.response.data) })

    })
  }
}

export default atividadeBasica;