<template>
  <v-form ref="form" lazy-validation @submit.prevent="salvar">
    <v-row>
      <v-col cols="12" lg="7">
        <v-select
          :items="clientes"
          item-text="nome"
          v-model="form.cliente"
          label="Cliente"
          outlined
          dense
          return-object
          ref="cliente"
          :rules="[(v) => !!v.id || 'Obrigatório']"
        ></v-select>
      </v-col>
      <v-col cols="12">
        <v-text-field
          label="Projeto"
          ref="nome"
          outlined
          dense
          v-model="form.nome"
          :rules="[rules.required]"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          label="Data Inicio"
          ref="dataInicio"
          outlined
          dense
          v-model="form.dataInicio"
          :rules="[rules.required]"
          v-mask="'##/##/####'"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          label="Data Fim"
          ref="dataFim"
          outlined
          dense
          v-model="form.dataFim"
          :rules="[rules.required]"
          v-mask="'##/##/####'"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          label="Data do vencimento"
          ref="dataPagamento"
          outlined
          dense
          v-model="form.dataPagamento"
          :rules="[rules.required]"
          v-mask="'##/##/####'"
        ></v-text-field>
      </v-col>

      <v-col cols="6">
        <v-row align="center" justify="end">
          <v-switch
            v-model="orcamento.aceito"
            color="success darken-2"
            @change="aceitar(orcamento.aceito)"
            hide-details
            label="aceito"
          >
          </v-switch>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-textarea
          outlined
          name="Observação"
          label="Observação"
          rows="3"
          row-height="15"
          v-model="form.observacao"
        ></v-textarea>
      </v-col>
      <v-col cols="12" align="right">
        <v-btn
          dark
          depressed
          color="primary"
          type="submit"
          :loading="loading"
          :disabled="loading"
          >Salvar</v-btn
        >
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Projeto from "@/service/projeto";
import Orcamento from "@/service/orcamento";
import moment from "moment";
export default {
  props: {
    clientes: {
      type: Array,
    },
    projeto: {
      type: Object,
    },
    orcamento: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        nome: "",
        dataInicio: "",
        dataFim: "",
        observacao: "",
        cliente: {},
      },
      rules: {
        required: (value) => !!value || "Obrigatório",
      },
    };
  },
  methods: {
    formToUpperCase() {
      if (!this.form) return;
      Object.keys(this.form).forEach((f) => {
        if (this.form[f] && typeof this.form[f] == "string") {
          this.form[f] = this.form[f].toUpperCase();
        } else if (this.form[f] && typeof this.form[f] == "object") {
          Object.keys(this.form[f]).forEach((i) => {
            if (this.form[f][i] && typeof this.form[f][i] == "string")
              this.form[f][i] = this.form[f][i].toUpperCase();
          });
        }
      });
    },
    async salvar() {
      if (!this.$refs.form.validate()) return;
      this.formToUpperCase();

      this.loading = true;

      

      const payload = {
        ...this.form,
        finalizado: this.projeto.finalizado || false,
        dataInicio: moment(this.form.dataInicio, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        dataFim: moment(this.form.dataFim, "DD/MM/YYYY").format("YYYY-MM-DD"),
        dataPagamento: moment(this.form.dataPagamento, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
      };
      try {
        await Projeto.save(payload);
        this.$notify({
          title: "Sucesso",
          text: "Projeto salvo",
          type: "success",
        });
        this.$emit("sucesso");
      } catch (error) {
        this.$notify({
          title: "Erro",
          text: "Ocorreu um erro ao salvar",
          type: "error",
        });
        this.loading = false;
      }
      this.loading = false;
    },
    async aceitar(aceito) {
      try {
        await Orcamento.aceito({
          orcamentoId: this.orcamento.id,
          aceito: aceito,
        });
        this.$notify({
          title: "Sucesso",
          text: aceito ? "Projeto aceito!" : "Projeto não aceito!",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$notify({
          title: "Erro",
          text: "Descupe! ocorreu um erro no sistema",
          type: "error",
        });
        this.orcamentoAceito = false;
      }
    },
  },
  mounted() {
    if(!this.projeto.id) return
    this.form = {
      ...this.projeto,
      dataInicio: moment(this.projeto.dataInicio, "YYYY MM DD").format(
        "DD/MM/YYYY"
      ),
      dataFim: moment(this.projeto.dataFim, "YYYY MM DD").format("DD/MM/YYYY"),
      dataPagamento:
        this.projeto.dataPagamento &&
        moment(this.projeto.dataPagamento, "YYYY MM DD").format("DD/MM/YYYY"),
    };
    this.orcamentoAceito = this.aceito;

    console.log(this.projeto);
  },
};
</script>

<style scoped></style>
