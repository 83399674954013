<template>
  <div>
    <v-form ref="form" lazy-validation @submit.prevent="salvar">
      <v-row>
        <v-col cols="12">
          <v-text-field
            label="Atividade"
            ref="atividade"
            outlined
            dense
            v-model="form.atividade"
            :rules="[rules.required]"
            clearable
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-select
            :items="fornecedores"
            item-text="nome"
            v-model="form.fornecedor"
            label="Fornecedor"
            outlined
            dense
            return-object
            ref="fornecedor"
            :rules="[(v) => !!v.id || 'Obrigatório']"
            required
            clearable
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12" sm="3" md="3" lg="3">
          <v-text-field
            label="Data Início"
            ref="dataInicio"
            outlined
            dense
            v-model.lazy="form.dataInicio"
            :rules="[rules.required]"
            type="date"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3" md="3" lg="3">
          <v-text-field
            label="Data Fim"
            ref="dataFim"
            outlined
            dense
            v-model.lazy="form.dataFim"
            :rules="[rules.required]"
            type="date"
            hide-details
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="2" md="2" lg="2">
          <vuetify-money
            v-model="form.valorOrcado"
            label="Valor Orçado"
            v-bind:outlined="true"
            v-bind:options="money"
            v-bind:valueWhenIsEmpty="'0,00'"
            dense
            hide-details
          />
        </v-col>
        <v-col cols="12" sm="2" md="2" lg="2">
          <vuetify-money
            v-model="form.valorRealizado"
            label="Valor Realizado"
            v-bind:outlined="true"
            v-bind:options="money"
            v-bind:valueWhenIsEmpty="'0,00'"
            dense
          />
        </v-col>
        <v-col cols="6" sm="1" md="1" lg="1">
          <span style="padding-top: 0; margin-top: 0">imposto?</span>
          <v-switch
            style="padding-top: 0; margin-top: 0"
            v-model="form.imposto"
            color="success darken-2"
          ></v-switch>
        </v-col>
        <v-col cols="6" sm="1" md="1" lg="1" align="right">
          <v-btn
            color="primary"
            dark
            :class="editando ? '' : 'square'"
            :loading="loading"
            :disabled="loading"
            type="submit"
          >
            <span v-if="editando">Salvar</span>
            <v-icon v-else>{{ mdiPlus }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-row>
      <v-card width="100%" class="mx-auto">
        <v-card-text>
          <v-simple-table dense fixed-header height="300px">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Atividade</th>
                  <th class="text-left">Fonecedor</th>
                  <th class="text-left">Período</th>
                  <th class="text-left">Valor Orçado</th>
                  <th class="text-left">Imposto(%7)</th>
                  <th class="text-left">Empresa(15%)</th>
                  <th class="text-left">Margem de Risco(10%)</th>
                  <th class="text-left">Valor Cliente</th>
                  <th class="text-left">Valor Realizado</th>

                  <th class="text-left">_____________________________</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in orcamentoFonecedores" :key="index">
                  <td>{{ item.atividade }}</td>
                  <td>
                    <a href="#" @click.stop="viewFornecedor(item.fornecedor)">{{
                      item.fornecedor.nome
                    }}</a>
                  </td>
                  <td class="text-caption">
                    {{ item.dataInicio | localDateToString }} a
                    {{ item.dataFim | localDateToString }}
                  </td>
                  <td>{{ item.valorOrcado | formataMoeda }}</td>
                  <td>
                    <span v-if="item.imposto">{{
                      item.percentImposto | formataMoeda
                    }}</span>
                    <span v-else>0</span>
                  </td>
                  <td>
                    <span v-if="item.imposto">{{
                      item.percentEmpresa | formataMoeda
                    }}</span>
                    <span v-else>0</span>
                  </td>
                  <td>
                    <span v-if="item.imposto">{{
                      item.percentRisco | formataMoeda
                    }}</span>
                    <span v-else>0</span>
                  </td>
                  <td class="font-weight-bold">
                    {{ item.valorCliente | formataMoeda }}
                  </td>
                  <td class="font-weight-bold">
                    {{ item.valorRealizado | formataMoeda }}
                  </td>

                  <td class="text-center">
                    <div style="display: inline-block">
                      <v-btn
                        icon
                        @click="abreFormaPagamentoMensal(item)"
                        :title="'forma de Pagamento'"
                        :color="'success darken-2'"
                      >
                        <v-icon>{{ mdiCreditCardSettingsOutline }}</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        @click="gerarContrato(item)"
                        :title="'Contrato'"
                        :color="'primary'"
                      >
                        <v-icon>{{ mdiFileDocumentEditOutline }}</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        color="primary darken-2"
                        @click="editar(item)"
                        title="Editar"
                      >
                        <v-icon>{{ mdiPencilBox }}</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        color="error darken-2"
                        @click="excluir(item)"
                        title="Excluir"
                      >
                        <v-icon>{{ mdiCloseBox }}</v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div class="text-center pt-2">
            <v-row>
              <v-col cols="12" sm="4" md="4" lg="4">
                <span class="font-weight-medium">Valor total Orcado: </span>
                <span class="font-weight-black">{{
                  valorTotalOrcado | formataMoeda
                }}</span>
              </v-col>
              <v-col cols="12" sm="4" md="4" lg="4">
                <span class="font-weight-medium">Valor total do Cliente: </span>
                <span class="font-weight-black">{{
                  valorTotalCliente | formataMoeda
                }}</span>
              </v-col>

              <v-col cols="12" sm="4" md="4" lg="4">
                <span class="font-weight-medium">Valor total Realizado: </span>
                <span class="font-weight-black">{{
                  valorTotalRealizado | formataMoeda
                }}</span>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-row>

    <v-dialog
      v-model="formaPagamentoDialog"
      transition="dialog-bottom-transition"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title
          >Forma Pagamento Mensal
          <v-spacer></v-spacer>

          <v-btn icon @click="formaPagamentoDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <FormaPagamento
            v-if="formaPagamentoDialog"
            :payload="formaPagamentoPayload"
            @sucesso="buscaOrcamentos"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="fornecDialog"
      transition="dialog-bottom-transition"
      max-width="600px"
    >
      <FornecedorView :objeto="fornecedor" @fechar="fornecDialog = false" />
    </v-dialog>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import {
  mdiPlus,
  mdiCloseBox,
  mdiPencilBox,
  mdiCreditCardSettingsOutline,
  mdiFileDocumentEditOutline,
} from "@mdi/js";
import Fornecedor from "@/service/fornecedor";
import Orcamento from "@/service/orcamento.js";
import FormaPagamento from "@/views/formaPagamentoMensal";
import FornecedorView from "@/views/components/FornecedorView";
import Relatorio from "@/service/relatorio.js";
import { saveAs } from "file-saver";
import moment from "moment";
export default {
  name: "OrcamentoForm",
  props: {
    projeto: {
      type: Object,
      require: true,
    },
  },
  components: { FormaPagamento, FornecedorView },
  data: () => ({
    mdiPlus,
    mdiCloseBox,
    mdiPencilBox,
    mdiCreditCardSettingsOutline,
    mdiFileDocumentEditOutline,
    loading: false,
    dialogDelete: false,
    fornecedores: [],
    orcamentoFonecedores: [],
    orcamentoId: "",
    form: {
      valorOrcado: "",
      valorRealizado: "",
      fornecedor: {},
      atividade: "",
      imposto: false,
    },
    rules: {
      required: (value) => !!value || "Obrigatório",
      naoZero: (value) => {
        if (!value) return true;
        if (
          parseFloat(
            value.trim().replace(".", "/").replace(",", ".").replace("/", ",")
          ) <= 0
        )
          return "Valor deve ser maior que Zero";
        return true;
      },
    },
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },
    editando: false,
    formaPagamentoDialog: false,
    formaPagamentoPayload: {},
    fornecDialog: false,
    fornecedor: null,
  }),
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    valorTotalCliente() {
      return this.orcamentoFonecedores
        .map((e) => e.valorCliente)
        .reduce((acc, valor) => (acc = acc + valor), 0);
    },
    valorTotalRealizado() {
      return this.orcamentoFonecedores
        .map((e) => e.valorRealizado)
        .reduce((acc, valor) => (acc = acc + valor), 0);
    },
    valorTotalOrcado() {
      return this.orcamentoFonecedores
        .map((e) => e.valorOrcado)
        .reduce((acc, valor) => (acc = acc + valor), 0);
    },
  },
  methods: {
    viewFornecedor(f) {
      this.fornecDialog = true;
      this.fornecedor = f;
      console.log(f);
    },
    formToUpperCase() {
      if (!this.form) return;
      Object.keys(this.form).forEach((f) => {
        if (this.form[f] && typeof this.form[f] == "string") {
          this.form[f] = this.form[f].toUpperCase();
        } else if (this.form[f] && typeof this.form[f] == "object") {
          Object.keys(this.form[f]).forEach((i) => {
            if (this.form[f][i] && typeof this.form[f][i] == "string")
              this.form[f][i] = this.form[f][i].toUpperCase();
          });
        }
      });
    },
    abreFormaPagamentoMensal(item) {
      this.formaPagamentoPayload = {
        item,
        orcamentoFornecedorId: item.id,
        valor: item.valorRealizado,
      };

      this.formaPagamentoDialog = true;
    },
    async salvar() {
      if (!this.$refs.form.validate()) return;

      this.formToUpperCase();

      const payload = {
        orcamento: {
          id: this.orcamentoId
            ? this.orcamentoId
            : this.projeto.orcamentoId
            ? this.projeto.orcamentoId
            : "",
          aceito: false,
          projeto: {
            id: this.projeto.projeto.id,
          },
        },
        orcamentoFornecedor: {
          id: this.form.id,
          valorOrcado: this.form.valorOrcado || 0,
          valorRealizado: this.form.valorRealizado,
          atividade: this.form.atividade,
          dataInicio: this.form.dataInicio,
          dataFim: this.form.dataFim,
          imposto: this.form.imposto,
          fornecedor: {
            id: this.form.fornecedor.id,
          },
        },
      };
      try {
        this.loading = true;
        const resp = await Orcamento.save(payload);

        this.orcamentoId = resp.id;

        this.$notify({
          title: "Sucesso",
          text: "Dados salvos!",
          type: "success",
        });
        this.form = {
          valorOrcado: "",
          valorRealizado: "",
          fornecedor: {},
          atividade: "",
          imposto: false,
          id: "",
        };
        this.editando = false;
        this.$refs.form.resetValidation();
        this.buscaOrcamentos();
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
      this.loading = false;
      this.$emit("sucesso");
    },
    valida() {
      let error = false;
      Object.keys(this.form).forEach((f) => {
        if (this.$refs[f] && !this.$refs[f].valid) {
          error = true;
          return;
        }
      });
      return error;
    },
    async buscaFornecedores() {
      try {
        const resp = await Fornecedor.findTodos();
        this.fornecedores = resp;
      } catch (error) {
        this.$notify({
          title: "Erro",
          text: error.title,
          type: "error",
        });
      }
    },
    async buscaOrcamentos() {
      this.formaPagamentoDialog = false;
      try {
        const resp = await Orcamento.fetchOrcamentosProjeto({
          projetoId: this.projeto.projeto.id,
        });

        this.orcamentoFonecedores = resp;
      } catch (error) {
        console.log(error);
      }
    },
    async excluir(item) {
      const res = await this.$confirm("Realmente deseja excluir o item?", {
        title: "Atenção",
        buttonTrueText: "SIM",
        buttonFalseText: "NÃO",
        color: "error",
      });
      if (res) {
        try {
          await Orcamento.delete({ id: item.id });
          await this.buscaOrcamentos();
        } catch (error) {
          this.$notify({
            title: "Erro",
            text: "Ocorreru um erro ao excluir o item",
            type: "error",
          });
        }
      }
    },
    async editar(item) {
      this.editando = true;

      this.form = {
        ...item,
        dataInicio: item.dataInicio
          ? moment(item.dataInicio, "YYYY,MM,DD").format("YYYY-MM-DD")
          : "",
        dataFim: item.dataFim
          ? moment(item.dataFim, "YYYY,MM,DD").format("YYYY-MM-DD")
          : "",
      };
    },
    async gerarContrato(item) {
      if (!item.dataInicio || !item.dataFim) {
        this.$notify({
          title: "Por favor",
          text: "Insira uma data de início e fim no orçamento.",
          type: "info",
        });
        return;
      }
      this.loading = true;
      const payload = {
        orcamentoId: item.id,
        tipo: "termops",
      };
      try {
        const resp = await Relatorio.gerar(payload);
        this.loading = false;
        saveAs(
          resp,
          `TERMO DE CONTRATAÇÃO PARA PRESTAÇÃO DE SERVIÇOS ${item.fornecedor.nome}.pdf`
        );
      } catch (error) {
        this.loading = false;
        this.$notify({
          title: "Erro",
          text: "Ocorreru um erro ao gerar o contrato",
          type: "error",
        });
      }
    },
  },
  async created() {
    this.loading = true;
    await this.buscaFornecedores();
    await this.buscaOrcamentos();
    this.loading = false;
  },
};
</script>

<style scoped>
div input {
  text-transform: uppercase;
}
a {
  text-decoration-line: none;
}
</style>
