import http from "./http";

const fornecedor = {
  //retorna fonecedores paginados
  findAll: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "GET",
        url: `fornecedores`,
        params: payload,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  //retorna fornecedores sem paginação
  findTodos: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "GET",
        url: `fornecedores/todos`,
        params: payload,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },



  findById: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "GET",
        url: `fornecedores?id=${payload.id}`,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  save: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "POST",
        url: `fornecedores`,
        data: payload,
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
  delete: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "DELETE",
        url: `fornecedores`,
        data: { ...payload },
      })
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
};

export default fornecedor;
