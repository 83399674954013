import http from "./http"

const Relatorio = {


  gerar: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "POST",
        url: `relatorios`,
        responseType: 'blob',
        data: payload
      })
        .then((resp) => { resolve(resp.data) })
        .catch((error) => { reject(error.response.data) })

    })
  },
  sendEmail: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "POST",
        url: `relatorios/email`,
        data: payload
      })
        .then((resp) => { resolve(resp.data) })
        .catch((error) => { reject(error.response.data) })

    })
  },

  contasPeriodo: async (payload) => {
    return new Promise((resolve, reject) => {
      http({
        method: "GET",
        url: `relatorios/contas`,
        responseType: 'blob',
        params: payload
      })
        .then((resp) => { resolve(resp.data) })
        .catch((error) => { reject(error.response.data) })

    })
  },
}

export default Relatorio;