<template>
  <v-card>
    <v-card-title
      >Fornecedor<v-spacer></v-spacer>
      <v-btn icon @click="$emit('fechar')">
        <v-icon>mdi-close</v-icon>
      </v-btn></v-card-title
    >
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <span class="caption">Nome</span><br />
          <span>{{ objeto.nome }}</span>
        </v-col>
        <v-col cols="6">
          <span class="caption">Atividade</span><br />
          <span>{{ objeto.atividade }}</span>
        </v-col>
        <v-col cols="6">
          <span class="caption">Telefone</span><br />
          <span>{{ objeto.telefone }}</span>
        </v-col>
        <v-col cols="6">
          <span class="caption">Documento</span><br />
          <span>{{ objeto.documento }}</span>
        </v-col>
        <v-col cols="6">
          <span class="caption">Profissão</span><br />
          <span>{{ objeto.profissao }}</span>
        </v-col>
        <v-col cols="6">
          <span class="caption">Nacionalidade</span><br />
          <span>{{ objeto.nacionalidade }}</span>
        </v-col>
        <v-col cols="6">
          <span class="caption">Estado Civil</span><br />
          <span>{{ objeto.estadoCivil }}</span>
        </v-col>
        <v-col cols="12" v-if="objeto.endereco">
          <span class="caption">Endereço</span><br />
          <span>{{ objeto.endereco.enderecoStr }}</span>
        </v-col>
        <v-col cols="6">
          <span class="caption">Observação</span><br />
          <span>{{ objeto.observacao }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    objeto: {
      type: Object,
      required: true,
    },
  },
  computed: {},
};
</script>

