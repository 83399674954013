<template>
  <div>
    <v-form ref="form" lazy-validation @submit.prevent="salvar">
      <v-row>
        <v-col cols="12" sm="7" md="7" lg="7">
          <v-text-field
            label="Custos Extras"
            ref="atividade"
            outlined
            dense
            v-model="form.nome"
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>
        <v-col cols="10" sm="3" md="3" lg="3">
          <vuetify-money
            v-model="form.valor"
            label="Valor"
            v-bind:outlined="true"
            v-bind:options="money"
            v-bind:valueWhenIsEmpty="'0,00'"
            :rules="[rules.required]"
            dense
          />
        </v-col>

        <v-col cols="2" sm="2" md="2" lg="2" align="right">
          <v-btn
            dark
            depressed
            color="primary"
            type="submit"
            :loading="loading"
            :disabled="loading"
            :class="form.id ? '' : 'square'"
          >
            <span v-if="form.id">salvar</span>
            <v-icon v-else>{{ mdiPlus }}</v-icon></v-btn
          >
        </v-col>
      </v-row>
    </v-form>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="atividades"
        :loading="loading"
        :options.sync="options"
        :server-items-length="pageable.totalElements"
        hide-default-footer
        dense
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Extras</v-toolbar-title>
          </v-toolbar>
        </template>

        <template v-slot:[`item.valor`]="{ item }">
          {{ item.valor | formataMoeda }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="primary" @click="editar(item)"
            ><v-icon small dark> {{ mdiPencil }} </v-icon>
          </v-btn>
          <v-btn icon color="error" @click="excluir(item)"
            ><v-icon small dark> {{ mdiClose }} </v-icon>
          </v-btn>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          v-model="options.number"
          :length="pageable.totalPages"
        ></v-pagination>
      </div>
    </v-card>
  </div>
</template>

<script>
import Atividade from "@/service/atividadeBasica";
import { mdiPlus, mdiPencil, mdiClose } from "@mdi/js";
export default {
  props: {
    projeto: {
      type: Object,
    },
  },
  data: () => ({
    loading: false,
    editando: false,
    form: {
      nome: "",
      valor: "",
    },
    rules: {
      required: (value) => !!value || "Obrigatório",
    },
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },
    atividades: [],
    dialog: false,
    mdiPlus,
    mdiPencil,
    mdiClose,
    options: {},
    pageable: {},
    payload: {},
    headers: [
      {
        text: "#",
        value: "id",
        align: "start",
        filterable: false,
        sortable: false,
      },
      {
        text: "Nome",
        value: "nome",
        filterable: false,
        sortable: false,
      },
      {
        text: "Homem Hora",
        value: "valor",
        align: "right",
        filterabldatae: false,
        sortable: false,
      },
      { text: "Ações", value: "actions", sortable: false, filterable: false },
    ],
  }),
  watch: {
    options: {
      async handler(val) {
        await Atividade.findAll({
          nome: this.nome || "",
          page: val.page - 1,
        });
      },
      deep: true,
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    formToUpperCase() {
      if (!this.form) return;
      Object.keys(this.form).forEach((f) => {
        if (this.form[f] && typeof this.form[f] == "string") {
          this.form[f] = this.form[f].toUpperCase();
        } else if (this.form[f] && typeof this.form[f] == "object") {
          Object.keys(this.form[f]).forEach((i) => {
            if (this.form[f][i] && typeof this.form[f][i] == "string")
              this.form[f][i] = this.form[f][i].toUpperCase();
          });
        }
      });
    },
    reset() {
      this.form = {
        nome: "",
        valor: "",
      };
    },
    async salvar() {
      if (!this.$refs.form.validate()) return;
      this.formToUpperCase();

      try {
        const payload = {
          ...this.form,
          projetoId: this.projeto.id,
        };
        await Atividade.save(payload);
        this.$notify({
          title: "Sucesso",
          text: "Custo extra adicionado!",
          type: "success",
        });
        this.reset();
        await this.inicializar();
      } catch (error) {
        console.log(error);
      }
    },
    editar(item) {
      this.form = { ...item };
    },
    async excluir(item) {
      try {
        await Atividade.delete(item);
        await this.inicializar();
        this.$notify({
          title: "Sucesso",
          text: "Custo extra excluido!",
          type: "success",
        });
      } catch (error) {
        console.log(error);
      }
    },

    async inicializar() {
      this.loading = true;
      try {
        const resp = await Atividade.findAllByProjetoId({
          projetoId: this.projeto.id,
        });
        this.atividades = resp.content;
        this.pageable = {
          totalElements: resp.totalElements,
          totalPages: resp.totalPages,
          pageSize: resp.size,
          page: resp.number,
        };
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.inicializar();
  },
};
</script>

<style></style>
